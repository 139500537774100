import * as React from 'react';
import { ISearchViewProps } from '../vinhuset-search';
import { ISearchFormViewProps } from './vinhuset-search.form';

type BlackoutOverlayResult = [ boolean, React.CSSProperties, React.Dispatch<React.SetStateAction<boolean>> ];

export const useBlackoutOverlay = (
    searchProps: ISearchViewProps,
    searchDivRef: React.MutableRefObject<HTMLDivElement | null>,
    isMobilePort: boolean,
    isSearchText: boolean,
    disableOverlay?: boolean
): BlackoutOverlayResult => {
    const [popupPosition, setPopupPosition] = React.useState<React.CSSProperties>({});
    const [isInputFocused, setIsInputFocused] = React.useState<boolean>(false);

    const searchInputRef = ((searchProps.form as ISearchFormViewProps).input as any).ref as React.RefObject<HTMLInputElement>;

    const updatePopupPosition = React.useCallback((searchPos: DOMRect, widthOffset: number) => {
        setPopupPosition({
            top: 'calc(100% - 1rem)',
            left: '1rem',
            right: '1rem'
        } as React.CSSProperties);
    }, [setPopupPosition]);

    React.useEffect(() => {
        if (!searchInputRef.current) {
            return;
        }

        const focus = () => { setIsInputFocused(true); };
        const blur = () => { setIsInputFocused(false); };

        searchInputRef.current.addEventListener('focus', focus);
        searchInputRef.current.addEventListener('blur', blur);

        return () => {
            searchInputRef.current!.removeEventListener('focus', focus);
            searchInputRef.current!.removeEventListener('blur', blur);
        };
    }, [searchInputRef.current]);

    React.useEffect(() => {
        // Make sure the search form is expanded when there is text in the input.
        // Fixes issue where the search form is collapsed when going from desktop to tablet/mobile view even with text in the input.
        if (isInputFocused && !searchProps.searchText && !searchProps.isSearchFormExpanded) {
            searchProps.callbacks.handleCancelSearchChange();
        }

        // Make sure the search form is collapsed when there is no text in the input.
        // Fixes issue where the search form is collapsed when going from desktop to tablet/mobile view even with text in the input.
        if (!isInputFocused && !searchProps.searchText && searchProps.isSearchFormExpanded) {
            searchProps.callbacks.handleCancelSearchChange();
        }
    }, [isInputFocused]);

    React.useEffect(() => {
        if (!searchDivRef.current) {
            return;
        }

        const resize = () => {
            updatePopupPosition(searchDivRef.current!.getBoundingClientRect(), -1.5);
        };

        window.addEventListener('resize', resize);

        return () => {
            window.removeEventListener('resize', resize);
        };
    }, [searchDivRef, searchDivRef.current, setPopupPosition]);

    const shouldShowOverlay = React.useMemo(() =>
        !disableOverlay && (isInputFocused || isSearchText || (isMobilePort && searchProps.isSearchFormExpanded)),
        [disableOverlay, isInputFocused, isSearchText, isMobilePort, searchProps.isSearchFormExpanded]
    );

    React.useEffect(() => {
        if (!shouldShowOverlay) {
            updatePopupPosition(searchDivRef.current!.getBoundingClientRect(), -1.5);
            return;
        }

        document.body.style.position = 'fixed';
        document.body.style.top = '0px';
        document.body.style.right = '17px';
        document.body.style.left = '0px';
        document.body.setAttribute('data-popup', 'true');

        updatePopupPosition(searchDivRef.current!.getBoundingClientRect(), -1.5);

        return () => {
            document.body.style.position = '';
            document.body.style.top = '';
            document.body.style.right = '';
            document.body.style.left = '';
            document.body.removeAttribute('data-popup');
        };
    }, [shouldShowOverlay, searchDivRef.current]);

    if (disableOverlay) {
        return [ false, popupPosition, setIsInputFocused ];
    }

    return [ shouldShowOverlay, popupPosition, setIsInputFocused ];
};
