import { ProductPrice } from "@msdyn365-commerce/retail-proxy";

export function multiplyPriceBy(price: ProductPrice, multiplier: number) {
    const newPrice = { ...price };

    if (newPrice.AdjustedPrice) {
        newPrice.AdjustedPrice *= multiplier;
    }

    if (newPrice.BasePrice) {
        newPrice.BasePrice *= multiplier;
    }

    if (newPrice.TradeAgreementPrice) {
        newPrice.TradeAgreementPrice *= multiplier;
    }

    if (newPrice.CustomerContextualPrice) {
        newPrice.CustomerContextualPrice *= multiplier;
    }

    if (newPrice.DiscountAmount) {
        newPrice.DiscountAmount *= multiplier;
    }

    if (newPrice.DiscountLines && newPrice.DiscountLines.length > 0) {
        newPrice.DiscountLines.forEach((line) => {
            if (line.EffectiveAmount) {
                line.EffectiveAmount *= multiplier;
            }
        });
    }

    return newPrice;
}
