import * as React from 'react';
import { ICoreContext } from '@msdyn365-commerce/core';
import { ProductPrice, SimpleProduct } from '@msdyn365-commerce/retail-proxy';
import { ICartState } from '@msdyn365-commerce/global-state';
import ProductListOrderQuantity from '../../../components/common/product-list-order-quantity';
import { AddToCartComponent, IAddToCartToastResources } from '../../vinhuset-buybox/common/add-to-cart.component';
import { ProductSearchResultExt } from '../../vinhuset-search-result-container/components';
import CartCount from './cart-count';
import PQueue from 'p-queue';
import { BuyValidationResult } from '../../../themes/vinhuset-adventureworks/views/components/product.component';
import { IVinhusetSearchResources } from '../vinhuset-search.props.autogenerated';

interface IBuySectionProps {
    context: ICoreContext;
    id: string;
    typeName: string;
    resources: IVinhusetSearchResources;
    productRecordId: number;
    simpleProduct: SimpleProduct | undefined;
    productPrice: ProductPrice | undefined;
    cart: ICartState;
    cartQueue: PQueue | undefined;
    inCartTooltipText: string | undefined;
    onBuyValidate: (product: ProductSearchResultExt, quantity: number) => Promise<BuyValidationResult>;
    onBreakoffChange?: (productRecordId: number, breakoff: boolean) => void;
}

const BuySection = (props: IBuySectionProps) => {
    const {
        id,
        typeName,
        context,
        resources,
        productRecordId,
        simpleProduct,
        productPrice,
        cart,
        cartQueue,
        inCartTooltipText
    } = props;
    const [quantity, setQuantity] = React.useState<number>(0);
    const [breakoff, setBreakoff] = React.useState<boolean>(false);
    const [forceUpdate , setForceUpdate] = React.useState(0);

    const onContainerClick = React.useCallback((e: React.MouseEvent) => {
        if ((e.target as HTMLElement).closest('.vinhuset-switch')) {
            // No need to prevent default if the click is on the switch and will in fact break the switch
            return;
        }

        e.preventDefault();
        e.stopPropagation();
    }, []);

    const toastStrings: IAddToCartToastResources = {
        errorHeader: resources.toastErrorHeader,
        productsAddedToCart: resources.productsAddedToCart,
        productsRemovedFromCart: resources.productsRemovedFromCart,
        productsUpdatedInCart: resources.productsUpdatedInCart,
        goToCart: resources.toastGoToCart,
        noAccessToProduct: resources.toastNoAccessToProduct
    };

    const productSearchResult: ProductSearchResultExt = {
        RecordId: productRecordId,
        Price: productPrice?.CustomerContextualPrice || 0
    };

    return (
        <div className='ms-search__buy-section' draggable onDragStart={e => e.preventDefault()} onClick={onContainerClick}>
            <ProductListOrderQuantity
                context={context}
                allowZero
                product={simpleProduct}
                locale={context.request.locale}
                currentCount={quantity}
                onChange={setQuantity}
                disabled={!simpleProduct}
                breakoff={breakoff}
                hideUnitConversion
                onBreakoffChange={(value: boolean) => {
                    setBreakoff(value);
                    props.onBreakoffChange?.(props.productRecordId, value);
                }}
            />

            {simpleProduct && (
                <div className='ms-search__buy-section__right'>
                    <CartCount key={forceUpdate} cart={cart} productId={productRecordId} inCartTooltipText={inCartTooltipText} />
                    <AddToCartComponent
                        id={id}
                        context={context}
                        typeName={typeName}
                        quantity={quantity}
                        breakoff={breakoff}
                        onUpdateQuantity={setQuantity}
                        data={{
                            cart,
                            product: simpleProduct,
                            price: productPrice,
                            productSearch: productSearchResult }}
                        cartQueue={cartQueue}
                        buyAlwaysAdd
                        addToCartText={resources.addToCartButtonText}
                        removeFromCartText='WIP Fjern fra kurv'
                        updateCartText='WIP Oppdater'
                        toastStrings={toastStrings}
                        onBuyValidate={props.onBuyValidate}
                        onAdd={async () => { setForceUpdate(forceUpdate + 1); }}
                    />
                </div>
            )}
        </div>
    );
};

export default BuySection;
